import { Ability } from '@casl/ability'
import useJwt from '@/cubus/jwt/useJwt'
import { initialAbility } from './config'

//  Read ability from localStorage
// * Handles auto fetching previous abilities if already logged in user
// ? You can update this if you store user abilities to more secure place
// ! Anyone can update localStorage so be careful and please update this

// const userData = JSON.parse(localStorage.getItem('userData'))
const t = localStorage.getItem(useJwt.jwtConfig.storageCubusTokenKeyName)
const userData = t ? { ability: { action: 'manage', subject: 'all' } } : null
const existingAbility = userData ? userData.ability : null

// console.log('ability', existingAbility || initialAbility)

const resultAbility = initialAbility
if (existingAbility) resultAbility.push(existingAbility)

// console.log('result ability', resultAbility)

// export default new Ability(existingAbility || initialAbility)
export default new Ability(resultAbility)
