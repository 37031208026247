export default [
  {
    path: '/cubus/objects',
    name: 'cubus-objects',
    component: () => import('@/cubus/components/objects/CbsObjects.vue'),
    meta: {
      title: 'Objects',
    },
  },
  {
    path: '/cubus/domains',
    name: 'cubus-domains',
    component: () => import('@/cubus/components/domains/CbsDomains.vue'),
  },
  {
    path: '/cubus/datasources',
    name: 'cubus-datasources',
    component: () => import('@/cubus/components/datasources/CbsDatasources.vue'),
  },
  {
    path: '/cubus/actions',
    name: 'cubus-actions',
    component: () => import('@/cubus/components/actions/CbsActions.vue'),
  },
  {
    path: '/cubus/object/:objectid',
    name: 'cubus-object',
    component: () => import('@/cubus/components/object/CbsObjectView.vue'),
  },
  {
    path: '/cubus/ribbon',
    name: 'cubus-ribbon',
    component: () => import('@/cubus/pages/profile/CbsRibbon.vue'),
  },
  {
    path: '/cubus/refs',
    name: 'cubus-refs',
    component: () => import('@/cubus/components/object-catalog/CbsObjectCatalog.vue'),
  },
  {
    path: '/cubus/tables',
    name: 'cubus-tables',
    component: () => import('@/cubus/components/object-table/CbsObjectsTable.vue'),
  },
  {
    path: '/cubus/cubes',
    name: 'cubus-cubes',
    component: () => import('@/cubus/components/object-cube/CbsObjectCube.vue'),
  },
  {
    path: '/cubus/imports',
    name: 'cubus-imports',
    component: () => import('@/cubus/components/object-import/CbsObjectsImport.vue'),
  },
  {
    path: '/cubus/reports',
    name: 'cubus-reports',
    component: () => import('@/cubus/components/object-report/CbsObjectsReport.vue'),
  },
  {
    path: '/cubus/procedures',
    name: 'cubus-procedures',
    component: () => import('@/cubus/components/object-procedure/CbsObjectsProcedure.vue'),
  },
  {
    path: '/cubus/mounts',
    name: 'cubus-mounts',
    component: () => import('@/cubus/components/object-mount/CbsObjectsMount.vue'),
  },
  {
    path: '/cubus/recordpage/:object/:record',
    name: 'record-page',
    component: () => import('@/cubus/components/record-page/CbsRecordPage.vue'),
    meta: {
      layout: 'full',
    },
  },
  /*
  {
    path: '/cubus/todo',
    name: 'cubus-todos',
    component: () => import('@/cubus/components/todo/CbsTodo.vue'),
  },
  */

  // *===============================================---*
  // *--------- TODO & IT'S FILTERS N TAGS ---------------------------------------*
  // *===============================================---*
  {
    path: '/cubus/todo',
    name: 'cubus-todo',
    component: () => import('@/cubus/components/todo/CbsTodo.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
    },
  },
  {
    path: '/cubus/todo/:filter',
    name: 'cubus-todo-filter',
    component: () => import('@/cubus/components/todo/CbsTodo.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
      navActiveLink: 'cubus-todo',
    },
    beforeEnter(to, _, next) {
      if (['important', 'completed', 'deleted'].includes(to.params.filter)) next()
      else next({ name: 'error-404' })
    },
  },
  {
    path: '/cubus/todo/tag/:tag',
    name: 'cubus-todo-tag',
    component: () => import('@/cubus/components/todo/CbsTodo.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
      navActiveLink: 'cubus-todo',
    },
    beforeEnter(to, _, next) {
      if (['team', 'low', 'medium', 'high', 'update'].includes(to.params.tag)) next()
      else next({ name: 'error-404' })
    },
  },

]
