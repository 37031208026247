// eslint-disable-next-line import/no-cycle,import/named
import { fetchGroupbyOperands } from '@/cubus/services/backendService.ts'

export default {
  namespaced: true,
  state: {
    isSessionLoaded: false,
    session: null,
    sessionStatus: 'init',
    sessionError: null,
    blankFilter: {
      node: {
        isactive: false,
        isexpanded: false,
        name: 'Root Node',
        oper: { id: 1, sid: 'and', name: 'AND' },
        nodes: [],
        conds: [],
      },
    },
    blankGroupby: {
      isActive: false,
      groups: [],
    },
    blankOrderby: {
      isActive: false,
      sorts: [],
    },
    argumentTypes: null,
    groupbyOperands: {
      data: [],
      loaded: false,
    },
  },
  getters: {
    SESSION: state => state.session,
    DOMAIN: state => {
      if (state.isSessionLoaded) {
        return state.session.domain
      }
      return null
    },
    USER: state => state.session.user,
    IS_SESSION: state => state.isSessionLoaded,
    SESSION_STATUS: state => state.sessionStatus,
    SESSION_ERROR: state => state.sessionError,
    BLANK_FILTER: state => state.blankFilter,
    BLANK_GROUPBY: state => state.blankGroupby,
    BLANK_ORDERBY: state => state.blankOrderby,
    ARGUMENT_TYPES: state => state.argumentTypes,
  },
  mutations: {
    // eslint-disable-next-line no-return-assign
    SET_SESSION: (state, payload) => {
      // console.log('SET session', payload)
      state.session = payload
      state.isSessionLoaded = true
      state.sessionStatus = 'loaded'
      state.sessionError = null
      // this.$i18n.locale = payload.user.languagesid
    },
    CLEAR_SESSION: state => {
      state.session = null
      state.isSessionLoaded = false
    },
    SET_SESSION_STATUS: (state, payload) => {
      state.sessionStatus = payload
    },
    SET_SESSION_ERROR: (state, payload) => {
      state.sessionError = payload
      state.sessionStatus = 'error'
    },
    SET_ARGUMENT_TYPES: (state, payload) => {
      state.argumentTypes = payload
    },
    setGroupbyOperands(state, newData) {
      state.groupbyOperands.data = newData
      state.groupbyOperands.loaded = true
    },
  },
  actions: {},
}
